<script setup lang="ts">
import {
  buildUrlPrefix,
  getProductName,
  getProductRoute,
  getSmallestThumbnailUrl,
} from "@shopware/helpers";
import { RouterLink } from "vue-router";

const props = defineProps<{
  product: any;
}>();
const { product } = props;

const srcPath = computed<string>(() => {
  if (product.cover?.media.thumbnails?.length > 0) {
    let thumb = `${getSmallestThumbnailUrl(product.cover?.media)}`;
    if (thumb) {
      return thumb;
    }
  }
  return `${product.cover?.media.url}`;
});

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
</script>

<template>
  <div class="slide text-center px-2.5">
    <figure
      class="w-[12.75rem] h-[12.75rem] mx-auto mb-5 flex items-center justify-center relative aspect-square"
    >
      <RouterLink
        :to="formatLink(getProductRoute(product))"
        class="overflow-hidden aspect-square h-full object-contain w-full"
      >
        <NuxtImg
          loading="lazy"
          format="webp"
          width="250"
          class="h-full object-contain w-full"
          :src="srcPath"
          :alt="getProductName({ product }) || ''"
          data-testid="product-box-img"
        />
      </RouterLink>
      <div
        v-if="product?.isNew"
        class="font-bold bg-skin-theme text-skin-white text-sm px-5 absolute rounded-full top-0 left-full -ml-5 group-[.slick-active]:opacity-100"
      >
        {{ $t("product.badges.new") }}
      </div>
    </figure>
    <div class="px-10">
      <p class="text-sm !mb-4">
        {{ $t("product.productNumber") }}: {{ product.productNumber }}
      </p>
      <h4 class="text-base font-bold mb-4 h-14">
        {{ product.translated.name }}
      </h4>
    </div>
    <div class="mt-5">
      <SharedButton
        :button="{
          url: formatLink(getProductRoute(product)),
          title: $t('learnMore'),
        }"
        :hasArrow="true"
        :class="'btn-outline btn-sm'"
      ></SharedButton>
    </div>
  </div>
</template>
